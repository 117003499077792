.login-background {
	width: 100vw;
	height: 93vh;
	background: #ecf0f3;
	z-index: 1;
	position: absolute;
}

.ps-login-container {
	position: relative;
}

.ps-login-input-group-addon {
	border: none;
}

.ps-login-box {
	background-color: var(--bg-c1);
	box-shadow: inset 4px 4px 4px 0px #d1d9e6, inset -4px -4px 4px 0px #fff;
	box-shadow: -1px -1px 7px 2px #fff, 8px 4px 12px 0px #d1d9e6;
	border-radius: 1em;
	margin-top: 7em;
	padding: 1em;
	max-width: 32em;
	min-width: 30em;
	text-align: center;
}

.ps-login-well, .ps-login-page-no-border {
	border: none;
}

.ps-login-well {
	box-shadow: inset 0 1px 3px 2px rgba(0,0,0,.05);
}

.ps-login-icon {
	font-size: 10em;
}

.ps-login-page {
	display: flex;
	justify-content: center;
}

.ps-login-input:focus {
	outline: 1px solid var(--border-3);
}

.ps-login-input {
	border: 1px solid var(--border-3);
}
